import React from 'react';
import { Row, Col } from 'antd';
import { ThemeProvider } from 'styled-components';

import '../styles/global.css';
import SEO from '../components/seo';
import CONTENT from '../data/homepage';
import theme from '../themes/helplineTheme';
import Hero from '../components/Helpline/Hero/Hero';
import Steps from '../components/Helpline/Steps/Steps';
import ATEForm from '../components/FormSection/ATEForm';
import Footer from '../components/Helpline/Footer/Footer';
import Header from '../components/Helpline/Header/Header';
import Leaders from '../components/Helpline/Leaders/Leaders';
import Features from '../components/Helpline/Features/Features';
import { getUrlParameter, getPagePhone } from '../helpers/helpers';
import Disclaimer from '../components/Helpline/Disclaimer/Disclaimer';
import Specialized from '../components/Helpline/Specialized/Specialized';
import ContentSection from '../components/Helpline/ContentSection/ContentSection';
import DcomHotlineStyles from '../components/Helpline/GlobalStyles/DcomHotline.styles';

const SITE_LANGUAGE = `${process.env.GATSBY_SITE_LANGUAGE}`;
// SEO
const {
  description, creator, title, url, fbImg, twImg, card, meta,
} = CONTENT[
  `${process.env.GATSBY_SITE_LANGUAGE}`
].seo;
// HEADER
const {
  header,
  hero,
  contentSection,
  steps,
  form,
  features,
  specialized,
  leaders,
  footer,
} = CONTENT[`${process.env.GATSBY_SITE_LANGUAGE}`];

const pid = getUrlParameter('pid');
const realPhone = getPagePhone(pid, SITE_LANGUAGE);

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <SEO
      description={description}
      creator={creator}
      title={title}
      url={url}
      fbImg={fbImg}
      twImg={twImg}
      card={card}
      meta={meta}
      lang={SITE_LANGUAGE}
    />
    <DcomHotlineStyles>
      <Header data={header} phone={realPhone} />
      <Hero data={hero} phone={realPhone} />
      <ContentSection data={contentSection} phone={realPhone} />
      <div className="content-section gray form-section-grey">
        <div className="container">
          <Row
            gutter={{
              xs: 24,
              sm: 32,
              md: 32,
              lg: 32,
            }}
          >
            <Col className="gutter-row" xs={24} md={12}>
              <Steps data={steps} />
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <ATEForm data={form} lang={SITE_LANGUAGE} phoneData={realPhone} />
            </Col>
          </Row>
        </div>
      </div>
      <Features data={features} />
      <Specialized data={specialized} />
      <Leaders data={leaders} />
      <Disclaimer lang={SITE_LANGUAGE} />
      <Footer data={footer} />
    </DcomHotlineStyles>
  </ThemeProvider>
);

export default IndexPage;
